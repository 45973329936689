import React from 'react'
import {MainLayout} from '../../layout'
import {ResetPasswordForm} from '../../components/auth'
import {resendAccountConfirmationCode, resetPassword} from '../../backend'
import {StringParam, useQueryParam} from 'use-query-params'
import {navigate} from '../../utils'

export default function PasswordReset() {
  const [phone] = useQueryParam('phone', StringParam)

  const handleResetPassword = async ({code, password}) => {
    await resetPassword({phone, code, password})
    navigate('/auth/login?password_reset=1')
  }

  const handleResendCode = async () => {
    return resendAccountConfirmationCode({phone})
  }

  return (
    <MainLayout>
      <ResetPasswordForm onResendCode={handleResendCode} onReset={handleResetPassword}/>
    </MainLayout>
  )
}
